import TokoService from "@/services/modules/TokoService";
import router from "@/router";

const tokoService = new TokoService();

const url = "super-admin/merchant";

export default {
  namespaced: true,
  state: {
    merchants: [],
    formPayload: {
      name: "",
      address: "",
      pic_name: "",
      pic_email: "",
      pic_handphone: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
  },
  getters: {},
  mutations: {
    SET_MERCHANT(state, merchants) {
      state.merchants = merchants;
    },
    SET_FORM_PAYLOAD(state, formPayload) {
      state.formPayload = formPayload;
    },
  },
  actions: {
    async getData({ commit }, { params }) {
      const response = await tokoService.getData(url, params);
      if (response) {
        commit("SET_MERCHANT", response.data.data);
      }

      return response.data.data;
    },
    async showData({ commit }, { id, params }) {
      const response = await tokoService.showData(url, id, params);
      if (response) {
        commit("SET_MERCHANT", response.data.data);
      }
    },
    async postData({ commit }, { id, payload }) {
      const response = await tokoService.postData(`${url}/${id}`, payload);

      return response;
    },
    async deleteData({ commit }, { id }) {
      const response = await tokoService.deleteData(url, id);

      return response;
    },
  },
};
