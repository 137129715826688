/* eslint-disable no-return-await */
import BaseService from '@/services/BaseService'

export default class BrandService extends BaseService {
  async getData(url, params) {
    return await this.getData(url, params)
  }

  async showData(url, id, params) {
    return await this.showData(url, id, params)
  }

  async postData(url, payload) {
    return await this.postData(url, payload)
  }

  async deleteData(url, id) {
    return await this.deleteData(url, id)
  }
}
