import SatuanService from '@/services/modules/SatuanService'
import router from '@/router'

const satuanService = new SatuanService()

const url = 'super-admin/uom'

export default {
  namespaced: true,
  state: {
    uoms: [],
    formPayload: {
      name: '',
      description: '',
    },
  },
  getters: {},
  mutations: {
    SET_UOMS(state, uoms) {
      state.uoms = uoms
    },
    SET_FORM_PAYLOAD(state, formPayload) {
      state.formPayload = formPayload
    },
  },
  actions: {
    async getData({ commit }, { params }) {
      const response = await satuanService.getData(url, params)
      if (response) {
        commit('SET_UOMS', response.data.data)
      }

      return response.data.data
    },
    async showData({ commit }, { id, params }) {
      const response = await satuanService.showData(url, id, params)
      if (response) {
        commit('SET_UOMS', response.data.data)
      }
    },
    async postData({ commit }, { id, payload }) {
      const response = await satuanService.postData(`${url}/${id}`, payload)

      return response
    },
    async deleteData({ commit }, { id }) {
      const response = await satuanService.deleteData(url, id)

      return response
    },
  },
}
