/* eslint-disable func-names */
/* eslint-disable class-methods-use-this */
import $axios from '@axios'

function BaseService() {
  // this.getData = function (url, params) {
  //   const getData = $axios.get(url, {
  //     params,
  //   })
  //     .then(res => {
  //       if (res.data) {
  //         return res.data.data
  //       }
  //       return false
  //     })
  //   return getData
  // }
  // this.postData = function (url, payload) {
  //   const body = new FormData()
  //   if (payload) {
  //     for (const param in payload) {
  //       body.append(param, payload[param])
  //     }
  //   }
  //   return $axios.post(url, body)
  //     .then(res => {
  //       if (res.data) {
  //         return res.data
  //       }
  //       return false
  //     })
  // }
  this.getData = function (url, params) {
    return $axios.get(url, {
      params,
    })
  }
  this.postData = function (url, payload) {
    return $axios.post(url, payload)
  }
  this.showData = function (url, id, params) {
    return $axios.get(`${url}/${id}`, {
      params,
    })
  }
  this.createData = function (url, payload) {
    return $axios.post(url, payload)
  }
  this.updateData = function (url, id, payload) {
    return $axios.post(`${url}/${id}`, payload)
  }
  this.deleteData = function (url, id) {
    return $axios.delete(`${url}/${id}`)
  }
}
export default BaseService
