import $http from "@axios";

export default {
  namespaced: true,
  state: {
    formPayload: {
      email: "",
      password: "",
      password_confirmation: "",
      branch_uuid: "",
      phone: "",
      phone_country: "",
      roles: [],
      permissions: [],
      selectedCountry: {
        country_code: "ID",
        country_flag: "https://flagsapi.com/ID/shiny/64.png",
        country_name: "Indonesia",
        dial_code_preview: "+62",
        dial_code_value: "62",
      },
    },
  },
  getters: {},
  mutations: {
    setFormPayload(state, value) {
      state.formPayload = value;
    },
  },
  actions: {
    getData(context, { uuid, params }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/super-admin/user-merchant/${uuid}`, { params })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDetail(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/super-admin/user-merchant/${uuid || ""}`, { params })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postData(context, { payload, params }) {
      return new Promise((resolve, reject) => {
        $http
          .post(`/super-admin/user-merchant${params}`, payload)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteData(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .delete(`/super-admin/user-merchant/${uuid}`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
