import BrandService from '@/services/modules/BrandService'
import router from '@/router'

const brandService = new BrandService()

const url = 'super-admin/brand'

export default {
  namespaced: true,
  state: {
    brands: [],
    formPayload: {
      name: '',
      description: '',
      logo: '',
    },
  },
  getters: {},
  mutations: {
    SET_BRANDS(state, brands) {
      state.brands = brands
    },
    SET_FORM_PAYLOAD(state, formPayload) {
      state.formPayload = formPayload
    },
  },
  actions: {
    // getData(context, { params }) {
    //   return new Promise((resolve, reject) => {
    //     $http.get('/brand', { params })
    //       .then(result => {
    //         resolve(result)
    //       }).catch(err => {
    //         reject(err)
    //       })
    //   })
    // },
    // getDetail(context, { params, uuid }) {
    //   return new Promise((resolve, reject) => {
    //     $http.get(`/brand/${uuid || ''}`, { params })
    //       .then(result => {
    //         resolve(result)
    //       }).catch(err => {
    //         reject(err)
    //       })
    //   })
    // },
    // postData(context, { payload, query }) {
    //   return new Promise((resolve, reject) => {
    //     $http.post(`/brand${query || ''}`, payload)
    //       .then(result => {
    //         resolve(result)
    //       }).catch(err => {
    //         reject(err)
    //       })
    //   })
    // },
    // deleteData(context, query) {
    //   return new Promise((resolve, reject) => {
    //     $http.delete(`/brand${query || ''}`)
    //       .then(result => {
    //         resolve(result)
    //       }).catch(err => {
    //         reject(err)
    //       })
    //   })
    // },
    async getData({ commit }, { params }) {
      const response = await brandService.getData(url, params)
      if (response) {
        commit('SET_BRANDS', response.data.data)
      }

      return response.data.data
    },
    async showData({ commit }, { id, params }) {
      const response = await brandService.showData(url, id, params)
      if (response) {
        commit('SET_BRANDS', response.data.data)
      }
    },
    async postData({ commit }, { id, payload }) {
      const response = await brandService.postData(`${url}/${id}`, payload)

      return response
    },
    async deleteData({ commit }, { id }) {
      const response = await brandService.deleteData(url, id)

      return response
    },
  },
}
