import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import money from 'v-money'
import VueHtmlToPaper from 'vue-html-to-paper'

import titleMixin from '@core/mixins/titleMixin'
import VueEcho from 'vue-echo-laravel'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/tour'

// Format Date
import '@/@core/utils/format-date'
// Vue Filter
import '@/@core/utils/vue-filters'

const { hostname, protocol, port } = window.location

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes',
  ],
  styles: [
    `${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/bootstrap.css`,
    // `${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/styles.css`,
    `${protocol}//${hostname}${port !== '' ? `:${port}` : ''}/landscape.css`,
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

// register directive v-money and component <money>
Vue.use(money, { precision: 4 })

Vue.mixin(titleMixin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

window.io = require('socket.io-client')

Vue.use(VueEcho, {
  broadcaster: 'socket.io',
  host: process.env.WEBSOCKET_DOMAIN,
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
