import BarangService from '@/services/modules/BarangService'
import router from '@/router'

const barangService = new BarangService()

const url = 'super-admin/item'

export default {
  namespaced: true,
  state: {
    items: [],
    formPayload: {
      name: '',
      description: '',
      logo: '',
    },
  },
  getters: {},
  mutations: {
    SET_ITEMS(state, items) {
      state.items = items
    },
    SET_FORM_PAYLOAD(state, formPayload) {
      state.formPayload = formPayload
    },
  },
  actions: {
    async getData({ commit }, { params }) {
      const response = await barangService.getData(url, params)
      if (response) {
        commit('SET_ITEMS', response.data.data)
      }

      return response.data.data
    },
    async showData({ commit }, { id, params }) {
      const response = await barangService.showData(url, id, params)
      if (response) {
        commit('SET_ITEMS', response.data.data)
      }
    },
    async postData({ commit }, { id, payload }) {
      const response = await barangService.postData(`${url}/${id}`, payload)

      return response
    },
    async deleteData({ commit }, { id }) {
      const response = await barangService.deleteData(url, id)

      return response
    },
  },
}
