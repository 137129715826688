export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      guest: true,
      layout: 'full',
    },
  },
  {
    path: '/',
    name: 'welcome',
    component: () => import('@/views/welcome.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/toko',
    name: 'toko.index',
    component: () => import('@/views/toko/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/cabang',
    name: 'cabang.index',
    component: () => import('@/views/cabang/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/gudang',
    name: 'gudang.index',
    component: () => import('@/views/gudang/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/customer',
    name: 'customer.index',
    component: () => import('@/views/customer/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('@/views/users/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/supplier',
    name: 'supplier.index',
    component: () => import('@/views/supplier/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/career',
    name: 'career.index',
    component: () => import('@/views/career/index.vue'),
    meta: {
      auth: true,
    },
  },
  {
    path: '/contact_us',
    name: 'contact_us.index',
    component: () => import('@/views/contact_us/index.vue'),
    meta: {
      auth: true,
    },
  },
]
