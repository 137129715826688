import $http from "@axios";

export default {
  namespaced: true,
  state: {
    formPayload: {
      name: "",
      email: "",
      permissions: [],
    },
  },
  getters: {},
  mutations: {
    setFormPayload(state, value) {
      state.formPayload = value;
    },
  },
  actions: {
    getData(context, { uuid, params }) {
      return new Promise((resolve, reject) => {
        $http
          .get(`/super-admin/users/${uuid}`, { params })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    postData(context, { payload, params }) {
      return new Promise((resolve, reject) => {
        $http
          .post(`/super-admin/users${params}`, payload)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteData(context, { uuid }) {
      return new Promise((resolve, reject) => {
        $http
          .delete(`/super-admin/users/${uuid}`)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
