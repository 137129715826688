import ProdukService from "@/services/modules/ProdukService";
import router from "@/router";

const produkService = new ProdukService();

const url = "super-admin/product";

export default {
  namespaced: true,
  state: {
    products: [],
    product: {},
    histories:[],
    formPayload: {
      item_uuid: "",
      brand_uuid: "",
      uom_uuid: "",
      specification: "",
      alias_name: "",
    },
    formPayloadLinkProduk: {
      merchant_product_uuid: "",
      default_product_uuid: "",
    },
  },
  getters: {},
  mutations: {
    SET_PRODUCTS(state, products) {
      state.products = products;
    },
    SET_PRODUCT(state, product) {
      state.product = product;
    },
    SET_HISTORIES(state, histories) {
      state.histories = histories;
    },
    SET_FORM_PAYLOAD(state, formPayload) {
      state.formPayload = formPayload;
    },
    SET_FORM_PAYLOAD_LINK_PRODUK(state, formPayloadLinkProduk) {
      state.formPayloadLinkProduk = formPayloadLinkProduk;
    },
  },
  actions: {
    async getData({ commit }, { params }) {
      const response = await produkService.getData(url, params);
      if (response) {
        commit("SET_PRODUCTS", response.data.data);
      }
      return response.data.data;
    },
    async showData({ commit }, { id, params }) {
      const response = await produkService.showData(url, id, params);
      if (response) {
        commit("SET_PRODUCT", response.data.data);
      }
    },
    async getHistory({ commit }, { params }) {
      const response = await produkService.getData(url + '/import-history', params);
      if (response) {
        commit("SET_HISTORIES", response.data.data);
      }
      return response.data.data;
    },
    async postData({ commit }, { id, payload }) {
      const response = await produkService.postData(`${url}/${id}`, payload);

      return response;
    },
    async postDataFile({ commit }, { payload }) {
      const response = await produkService.postData(`${url}/import`, payload);

      return response;
    },
    async deleteData({ commit }, { id }) {
      const response = await produkService.deleteData(url, id);

      return response;
    },
  },
};
