import $http from '@axios'

export default {
  namespaced: true,
  state: {
    editId: null,
    formPayload: {
      name: '',
      phone_number: '',
      tax_number: '',
      address: '',
      sales_name: '',
      sales_phone_number: '',
      terms_of_payment_day: 0,
      payment_type: '',
      payment_date: '',
    },
    payment_type: null,
    shorcutTotal: 0,
    formPayloadUtang: {},
    result: {},
    detailUtang: {},
    closeModal: false,
    total: 0,
  },
  getters: {},
  mutations: {
    SET_SHORCUT_TOTAL(state, value) {
      state.shorcutTotal = value
    },
    SET_PAYMENT_TYPE(state, value) {
      state.payment_type = value
    },
    setCloseModal(state, value) {
      state.closeModal = value
    },
    setFormPayloadUtang(state, value) {
      state.formPayloadUtang = value
    },
    setDetailUtang(state, value) {
      state.detailUtang = value
    },
    setEditId(state, value) {
      state.editId = value
    },
    setFormPayload(state, value) {
      state.formPayload = value
    },
    setResult(state, value) {
      state.result = value
    },
    setTotal(state, value) {
      state.total = value
    },
  },
  actions: {
    getData(context, { params }) {
      return new Promise((resolve, reject) => {
        $http.get('/super-admin/supplier', { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    getDetailSupplier(context, { params, uuid }) {
      return new Promise((resolve, reject) => {
        $http.get(`/super-admin/supplier/${uuid || ''}`, { params })
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postData(context, { payload, query }) {
      return new Promise((resolve, reject) => {
        $http.post(`/supplier${query || ''}`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    postUtang(context, { payload, supplierUuid }) {
      return new Promise((resolve, reject) => {
        $http.post(`/utang/${supplierUuid || ''}/add-payment`, payload)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    deleteData(context, query) {
      return new Promise((resolve, reject) => {
        $http.delete(`${query || ''}`)
          .then(result => {
            resolve(result)
          }).catch(err => {
            reject(err)
          })
      })
    },
    clearItem(context) {
      context.commit('setNpwpUrl', null)
      context.commit('setKtpUrl', null)
      context.commit('setEditId', null)
      context.commit('setFormPayload', {
        customer_type: '',
        email: '',
        name: '',
        alias_name: '',
        phone_number: '',
        contact_name: '',
        address: '',
        terms_of_payment: '',
        tax_id_number: '',
        id_card_number: '',
      })
    },
  },
}
